
import { toast, ToastContainer } from 'react-toastify';

export const Toast = (props) => {
  return (
    <>
      <ToastContainer
        position={props.position ? props.position : 'top-right'}
        autoClose={props.autoClose ? props.autoClose : 2000}
        hideProgressBar={props.hideProgressBar ? props.hideProgressBar : false}
        newestOnTop={props.newestOnTop ? props.newestOnTop : false}
        closeOnClick={props.closeOnClick ? props.closeOnClick : true}
        rtl={props.rtl ? props.rtl : false}
        pauseOnFocusLoss={props.pauseOnFocusLoss ? props.pauseOnFocusLoss : true}
        draggable={props.draggable ? props.draggable : true}
        pauseOnHover={props.pauseOnHover ? props.pauseOnHover : true}
        theme={props.theme ? props.theme : 'colored'}
      >
      </ToastContainer>
    </>
  );
};

export const toastMessage = (message, type) => {
  type = type.toLowerCase();
  if (type && toast[type]) {
    toast[type](message);
  } else {
    toast(message);
  }
};