
import DashboardFooter from '../DashboardFooter/DashboardFooter';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { usePageMeta } from '../../Hooks/CustomHooks';
import { lazy } from 'react';

const Header = lazy(() => import('../Header/Header'));
const Footer = lazy(() => import('../Footer/Footer'));

function PreLoginLayout({ component, footerType = 'prelogin' }) {
  usePageMeta()
  const activeUserData = useSelector((state) => state.activeUserData);
  const userData = useSelector((state) => state.userData);



  return (
    <>
      <Header />
      {Object.keys(userData).length !== 0 && activeUserData?.val?.user_id &&
        <Box className={'ViewingMemberStrip'}>
          <h2>You are accessing the portfolio
            of {activeUserData?.val?.firstName} {activeUserData?.val?.lastName}</h2>
          {/*<button onClick={() => {*/}
          {/*    dispatch(setActiveUserData({}));*/}
          {/*}}>View as Admin </button>*/}
        </Box>
      }
      {component}
      {footerType === 'prelogin' ? <Footer /> : <DashboardFooter />}
    </>
  );
}
export default PreLoginLayout;
