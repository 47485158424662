import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUserData } from '../Reducers/userReducer';
import { setActiveUserData } from '../Reducers/activeUserReducer';

const BASE_URL = process.env.REACT_APP_API_DOMAIN;

export const useApiService = () => {
  const dispatch = useDispatch();
  const activeUserData = JSON.parse(localStorage.getItem('activeUserData'));

  const fetchData = async (endpoint, passActiveHeader = true, timeout = 50000,) => {
    let token = '';
    if (process.env.NODE_ENV === 'development' && JSON.parse(localStorage.getItem('userData'))?.token) {
      // eslint-disable-next-line no-unused-vars
      token = JSON.parse(localStorage.getItem('userData')).token;
    }
    // const headers = token ? { Authorization: `Bearer ${token}` } : {};
    let headers = activeUserData?.val?.user_id && passActiveHeader ? { 'viewing-user': activeUserData?.val?.user_id } : {};
    if (token) {
      headers.Authorization = token;
    }
    try {
      const response = await axios.get(`${BASE_URL}${endpoint}`, {
        withCredentials: true,
        headers,
        timeout
      });
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled due to timeout:', error.message);
      } else {

        if (error?.response?.status === 401) {
          // Dispatch setUserData({}) for logout
          dispatch(setUserData({}));
          dispatch(setActiveUserData({}));
          if (process.env.NODE_ENV !== 'development') {
            window.location.href = '/signin';
          }
        }

        // throw error;
      }
    }
  };

  const postData = async (endpoint, data, passActiveHeader = true, shouldLogout = true, timeout = 50000) => {
    let token = '';
    if (process.env.NODE_ENV === 'development' && JSON.parse(localStorage.getItem('userData'))?.token) {
      // eslint-disable-next-line no-unused-vars
      token = JSON.parse(localStorage.getItem('userData')).token;
    }
    // const headers = token ? { Authorization: `Bearer ${token}` } : {};

    let headers = activeUserData?.val?.user_id && passActiveHeader ? { 'viewing-user': activeUserData?.val?.user_id } : {};
    console.log('check2', token?.length);

    if (token) {
      headers.Authorization = token;
    }
    try {
      const response = await axios.post(`${BASE_URL}${endpoint}`, data, {
        withCredentials: true,
        headers,
        timeout
      });

      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled due to timeout:', error.message);
      } else {
        if (error?.response?.status === 401 && shouldLogout) {
          console.log('Logout');
          // Dispatch setUserData({}) for logout
          dispatch(setUserData({}));
          dispatch(setActiveUserData({}));
          if (process.env.NODE_ENV !== 'development') {
            window.location.href = '/signin';
          }
        }
        throw error;
      }
    }
  };

  return { fetchData, postData };
};
