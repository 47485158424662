// store.js
import {configureStore} from '@reduxjs/toolkit';
import userReducer from './Redux/Reducers/userReducer';
import loggerMiddleware from './Redux/Middleware/loggerMiddleware';
import thunk from 'redux-thunk';
import activeUserReducer from './Redux/Reducers/activeUserReducer';

const store = configureStore({
  reducer: {
    userData: userReducer,
    activeUserData: activeUserReducer
    // Add other reducers here
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(loggerMiddleware, thunk)
});

export default store;