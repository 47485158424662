import React, { useState } from 'react';
import './DashboardFooterStyles.css';
import { Box, Container, Dialog, Slide, useMediaQuery } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { INVESTER_TYPE, MOBILE_DASHBOARD_FOOTER } from '../../../../Utils/constants';
import { Button } from '../../../../Components/Utils/MuiStylesComponents';
import { useApiService } from '../../../../Redux/Services/Api';
import { toastMessage } from '../Toast/Toast';
import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DashboardFooter = () => {
  const { pathname } = useLocation();
  const isSmallScreen = useMediaQuery('(max-width:768px)');
  const [openRestrateKyc, setOpenRestartKyc] = useState(false)
  const { postData } = useApiService();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userData);
  const activeUserData = useSelector((state) => state.activeUserData);

  const handleCloseRestrateKyc = () => {
    setOpenRestartKyc(false)
  }

  const handleClickRestartKyc = async () => {
    try {
      const url = process.env.REACT_APP_CHANGE_ACCOUNT_TYPE;
      let response = await postData(url);
      if (response?.success) {
        navigate('/kyc');
      } else {
        if (response?.message) {
          toastMessage(response?.message, 'error');
        }
      }
    } catch (e) {
      if (e?.response?.data?.message) {
        toastMessage(e?.response?.data?.message, 'error');
      }
      console.log('error, something went wrong');
    }
  }

  const MobileDashboardFooter = () => {
    return (
      <Box className='MobileDashboardFooterContainer'>
        <Box className="MobileDashboardFooter">
          {MOBILE_DASHBOARD_FOOTER.map(({ icon, label, url }, i) => (
            <Box className="MobileDashboardFooterItem" component={Link} to={url} key={i}>
              <img loading='lazy' src={`${process.env.REACT_APP_CDN_ICON_RESOURCE_URL}${icon}`} alt={icon} />
              <p>{label}</p>
            </Box>
          ))}
        </Box>
      </Box>
    )
  }

  const WebFooterFooter = () => {
    const parts = pathname.split("/");
    let kycType = '';
    let label = '';
    console.log("parts", parts, "kyctype", kycType);

    const investorTypeLabel = (kycType) => {
      const selected = INVESTER_TYPE.filter((item) => {
        return `/kyc/${kycType}` === item?.link
      });
      console.log('kk', selected);
      return selected[0]?.label;
    }

    if (activeUserData?.val?.user_id) {
      if (activeUserData?.val?.account_type) {
        kycType = activeUserData?.val?.account_type;
        label = investorTypeLabel(kycType);
      }
    }
    else {
      if (userData?.account_type) {
        kycType = userData?.account_type;
        label = investorTypeLabel(kycType);
      }
    }




    return (
      <>
        {
          pathname.includes(`kyc/${kycType}`) ?
            <Box className="RestartKycContainer">
              <span>You are doing your KYC as an </span>
              <span><strong>{label} </strong></span>
              <span className='ClickHereButton' onClick={() => setOpenRestartKyc(true)} >
                <img loading='lazy' src={`${process.env.REACT_APP_CDN_ICON_RESOURCE_URL}edit1.svg`} alt={'edit-icon'} /> Click here</span>
              <span> to change your Investor Type  </span>
            </Box>
            :
            <Box className="DashboardFooter">
              <Container maxWidth='xl' sx={{ padding: '0 6rem', paddingLeft: '6rem !important', paddingRight: '6rem !important' }}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                  <Box className="DashboardFooterLEft">
                    <Link to={'/privacy-policy'} style={{ textDecoration: 'none' }}>
                      <p>Privacy Policy</p>
                    </Link>
                    <Link to={'/terms-and-conditions'} style={{ textDecoration: 'none' }}>
                      <p>Terms and Conditions </p>
                    </Link>
                  </Box>
                  <Box className="DashboardFooterRight">
                    <p>Made with ❤️  in India | © Copyright 2024, All Rights Reserved</p>
                  </Box>
                </Box>
              </Container>
            </Box >
        }
      </>
    )
  }
  return (
    <>
      {isSmallScreen && !pathname.includes('kyc') ? <MobileDashboardFooter /> : <WebFooterFooter />}
      <Dialog
        open={openRestrateKyc}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseRestrateKyc}
      >
        <Box className="RestartKycDialog">
          <img loading='lazy' src={`${process.env.REACT_APP_CDN_IMAGE_RESOURCE_URL}restartkyc.svg`} alt={'edit-icon'} />
          <h3>This will start over the KYC process</h3>
          <small>Are you sure you want to change your Investor Type?</small>
          <Box className="ResetKycButtonContaner">
            <Button onClick={handleCloseRestrateKyc}>Cancel</Button>
            <Button color='primary' onClick={handleClickRestartKyc}>Yes</Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default DashboardFooter;
