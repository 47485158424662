import WiseXRoute from './Routes/Routes';
import { Provider } from 'react-redux';
import store from './store';
import ErrorBoundary from './Utils/ErrorBoundary';


/**
 * @function App
 * @description The main component of the application
 * @returns {JSX.Element} The app component
 */
function App() {

  return (
    // <ErrorBoundary>
    <Provider store={store}>
      <WiseXRoute />
    </Provider>
    // </ErrorBoundary>
  );
}

export default App;