import {createSlice} from '@reduxjs/toolkit';

const activeUserReducer = createSlice({
  name: 'activeUserData',
  initialState: JSON.parse(localStorage.getItem('activeUserData')) ? JSON.parse(localStorage.getItem('activeUserData')) : {},
  reducers: {
    setActiveUserData: (state, action) => {
      console.log('payload',action.payload);
      if(Object.keys(action.payload).length === 0){
        localStorage.removeItem('activeUserData');
        return {};
      }
      else{
        localStorage.setItem('activeUserData', JSON.stringify({...state, ...action.payload}));
        return {
          ...state,
          ...action.payload
        };
      }

    }

  }
});

export const {setActiveUserData} = activeUserReducer.actions;

export default activeUserReducer.reducer;