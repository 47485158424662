// usePageMeta.js
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation from react-router-dom
import { WISEX_PAGES_META_DATA } from "../../../Utils/constants";

export const usePageMeta = (defaultTitle = "WiseX") => {
  const location = useLocation();

  const getMetaFromRoute = (pathname) => {
    return WISEX_PAGES_META_DATA[pathname] || { title: null, description: null, keywords: null };
  };
  const updateMetaTags = (title, description, keywords, defaultTitle) => {
    document.title = title ? title : defaultTitle;
    setMetaTag('description', description);
    setMetaTag('keywords', keywords);
    setMetaTag('title', title);
  };

  const setMetaTag = (name, content) => {
    let metaTag = document.querySelector(`meta[name="${name}"]`);

    if (metaTag) {
      metaTag.setAttribute("content", content || "");
    } else {
      metaTag = document.createElement("meta");
      metaTag.name = name;
      metaTag.content = content || "";
      document.head.appendChild(metaTag);
    }
  };

  useEffect(() => {
    const { title, description, keywords } = getMetaFromRoute(location.pathname);
    updateMetaTags(title, description, keywords, defaultTitle);
  }, [location.pathname]);

};




export const useIntersectionObserver = (options, fetchDataCallback) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);
  const hasFetched = useRef(false); // Ref to track if data has been fetched

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [options]);

  useEffect(() => {
    if (isIntersecting && !hasFetched.current) {
      fetchDataCallback().then(() => {
        hasFetched.current = true; // Set the flag to true after fetching data
      });
    }
  }, [isIntersecting, fetchDataCallback]);

  return [ref, isIntersecting];
};


export const useImagePreload = (desktopSrc, mobileSrc) => {
  useEffect(() => {
    const isMobile = window.matchMedia('(max-width: 768px)').matches;
    const src = isMobile && mobileSrc ? mobileSrc : desktopSrc;

    if (!src) return;

    // Create and append preload link element
    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = src;
    link.as = 'image';
    document.head.appendChild(link);

    // Preload image
    const img = new Image();
    img.src = src;
    img.fetchpriority = 'high'; // Ensure the image has high fetch priority
    // img.onload = () => console.log(`Image preloaded: ${src}`);
    // img.onerror = () => console.error(`Failed to preload image: ${src}`);

    // Cleanup function to remove link element from head when component unmounts
    return () => {
      document.head.removeChild(link);
    };
  }, [desktopSrc, mobileSrc]);
};



export const useImagePreloadWithCallBack = (desktopSrc, mobileSrc, onLoadCallback) => {
  useEffect(() => {
    const isMobile = window.matchMedia('(max-width: 768px)').matches;
    const src = isMobile && mobileSrc ? mobileSrc : desktopSrc;

    if (!src) return;

    // Create and append preload link element
    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = src;
    link.as = 'image';
    document.head.appendChild(link);

    // Preload image
    const img = new Image();
    img.src = src;
    img.fetchpriority = 'high'; // Ensure the image has high fetch priority

    // Set a callback when image is loaded
    img.onload = () => {
      onLoadCallback();
    };

    // Cleanup function to remove link element from head when component unmounts
    return () => {
      document.head.removeChild(link);
    };
  }, [desktopSrc, mobileSrc, onLoadCallback]);
};

