
function Sitemap() {
  return (
    <div>
      <h1>XML Sitemap</h1>
      <iframe src="/sitemap.xml" title="XML Sitemap" width="100%" height="600px" />
    </div>
  );
}

export default Sitemap;
