import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import styled from '@emotion/styled';
import { Button as button, TextField } from '@mui/material';

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#D9D9D9'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#00C08D'
  }
}));

export const Button = styled(button)(({ color }) => ({
  textTransform: 'capitalize',
  borderRadius: '0.5rem',
  backgroundColor: color === 'primary' ? '#00A076' : (color === 'secondary' ? '#f3f3f3' : '#102348'),
  color: color === 'secondary' ? '#1A1A1A' : '#fff',
  padding: '0.875rem',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  '&:hover': {
    backgroundColor: '#102348',
    color: '#fff'
  },
  '&:disabled': {
    backgroundColor: '#D9D9D9',
    color: '#383838',
    boxShadow: 'none'
  }

}));

export const InputField = styled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: '#00C08D'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#102348'
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '#102348'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00C08D'
    }
  }
}));