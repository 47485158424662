import { IconButton } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { useLocation } from "react-router";
import jsPDF from "jspdf";
import pdfHeaderImage from '../../Image/downloadPdfHeader.jpg';

export const handleCheckPhoneEmail = (input) => {
  const phoneRegex = /^\d{7,13}$/;
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  const isPhoneNumber = phoneRegex.test(input.slice(0, 13));
  const isEmail = emailRegex.test(input);
  let result = {
    isValid: false,
    value: isPhoneNumber ? input.slice(0, 13) : input,
  };
  if (input.length > 0) {
    result.isValid = isPhoneNumber || isEmail;
    result.showCountryCode = /^\d+$/.test(input); // Check if input consists only of numbers
  }
  return result;
};

export const handleCheckPhone = (input) => {
  const tenDigitVal = input.slice(0, 13);
  const phoneRegex = /^\d{7,13}$/;
  const isPhoneNumber = phoneRegex.test(tenDigitVal);
  return {
    isValid: tenDigitVal.length > 0 && isPhoneNumber,
    value: tenDigitVal,
  };
};

export const handleCheckEmail = (input) => {
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  const isEmail = emailRegex.test(input);
  return { isValid: input.length > 0 && isEmail, value: input };
};
export const handleCheckName = (input) => {
  const nameRegex = /^[a-zA-Z ]*$/;
  const isValid = typeof input === "string" && nameRegex.test(input);
  return { isValid, value: input };
};

export const handleCheckAadhaar = (input) => {
  const aadhaarRegex = /^\d{12}$/;
  const isAadhaar = aadhaarRegex.test(input);
  return { isValid: input.length === 12 && isAadhaar, value: input };
};

export function formatNumber(number) {
  const crore = 10000000;
  const lakh = 100000;

  if (number >= crore) {
    const formatted = (number / crore).toFixed(2);
    return formatted.endsWith(".00")
      ? formatted.slice(0, -3) + "Cr"
      : formatted + "Cr";
  } else if (number >= lakh) {
    const formatted = (number / lakh).toFixed(2);
    return formatted.endsWith(".00")
      ? formatted.slice(0, -3) + "L"
      : formatted + "L";
  } else if (number >= 1000) {
    const formatted = (number / 1000).toFixed(2);
    return formatted.endsWith(".00")
      ? formatted.slice(0, -3) + "k"
      : formatted + "k";
  } else {
    return number.toString();
  }
}

export const convertDateWithTime = (val) => {
  // date pattern = 12:00 AM January 11, 2024

  const timestamp = val * 1000; // Convert to milliseconds
  const dateOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const timeFormatted = new Intl.DateTimeFormat("en-US", dateOptions).format(
    new Date(timestamp)
  );

  const dateOptionsFull = {
    month: "long",
    day: "numeric",
    year: "numeric",
  };

  const dateFormatted = new Intl.DateTimeFormat(
    "en-US",
    dateOptionsFull
  ).format(new Date(timestamp));

  const formattedDate = `${timeFormatted} ${dateFormatted}`;
  return formattedDate;
};

export const convertDate = (val = 1705042004) => {
  const timestamp = val * 1000; // Convert to milliseconds

  const dateOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", dateOptions).format(
    new Date(timestamp)
  );
  return formattedDate;
};

export const transformData = (data) => {
  return Object.entries(data).map(([heading, documents]) => ({
    heading,
    val: documents,
  }));
};

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }, [pathname]);

  return null;
}

export function formatAmountWithCommas(amount) {
  // Check if the amount is a valid number and not NaN
  const isValidAmount = !isNaN(amount) && typeof amount === "number";

  // If the amount is valid, format it with commas
  if (isValidAmount) {
    // Convert the number to a string and use regex to insert commas
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    // Return an empty string or handle the error as per your requirement
    return "";
  }
}

export const getPdfNumberOfPages = async (event) => {
  const file = event.target.files[0];
  const pageCount = await loadPdf(file);
  return pageCount;
};

const loadPdf = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      const buffer = event.target.result;
      try {
        const pageCount = await getPageCount(buffer);
        resolve(pageCount);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
    if (file) {
      reader.readAsArrayBuffer(file);
    }
  });
};

const getPageCount = async (buffer) => {
  if (!window.pdfjsLib) {
    return 0;
  }
  const url = URL.createObjectURL(new Blob([buffer]));
  try {
    const loadingTask = window.pdfjsLib.getDocument(url);
    const pdf = await loadingTask.promise;
    return pdf.numPages;
  } catch (error) {
    console.error("Error loading PDF:", error);
    return 0;
  }
};



export const exportToCSV = (data, fileName = 'Data') => {
  // Helper function to transform headers
  const transformHeader = (header) => {
    return header.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  };

  // Helper function to format date from epoch
  const formatDate = (epoch) => {
    const date = new Date(epoch * 1000); // Convert seconds to milliseconds
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    }).replace(/,/g, '');
  };



  // Convert JSON data to CSV
  const csvRows = [];
  const headers = Object.keys(data[0]);
  csvRows.push(headers.map(transformHeader).join(','));

  data.forEach(row => {
    const values = headers.map(header => {
      let value = row[header];
      if (typeof value === 'number' && (header.toLowerCase().includes('date') || header.toLowerCase().includes('timestamp'))) {
        value = formatDate(value);
      }
      const escaped = ('' + value).replace(/"/g, '\\"');
      return `"${escaped}"`;
    });
    csvRows.push(values.join(','));
  });

  const csvString = csvRows.join('\n');

  // Create a Blob from the CSV string
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', `${fileName}.csv`);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


export const downloadIncomeStatementPDF = (headers, data, heading) => {
  const doc = new jsPDF('p', 'mm', 'a4');

  // Helper function to transform headers
  const transformHeader = (header) => {
    return header.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  };

  // Transform the headers
  const transformedHeaders = headers.map(transformHeader);

  // Add heading
  doc.setFontSize(16);
  doc.text(heading, 75, 30);

  // Get the page width
  const width = doc.internal.pageSize.getWidth();
  doc.addImage(pdfHeaderImage, 'JPEG', 0, 0, width, 25);
  // doc.addImage(headers, 'JPEG', 0, 0, width, 25);

  // Set the initial startY position for the table
  let startY = 40;

  // Format the data for autoTable
  const tableData = data.map(item =>
    headers.map(header => {
      if (header.toLowerCase().includes('date') && typeof item[header] === 'number') {
        return new Date(item[header] * 1000).toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        });
      }
      if (header.includes('receipt') && typeof item[header] === 'string') {
        return item[header].slice(0, 10); // Showing only first 10 characters of the receipt URL
      }
      return item[header];
    })
  );

  // Generate the table
  doc.autoTable({
    startY: startY,
    head: [transformedHeaders],
    body: tableData,
    theme: 'grid',
    headStyles: {
      fillColor: [22, 160, 133],
      textColor: [255, 255, 255],
      fontStyle: 'bold'
    },
    columnStyles: {
      0: { cellWidth: 'auto', halign: 'left' },
      1: { cellWidth: 'auto', halign: 'left' },
      // Adjust the alignment and width for other columns as needed
    },
    styles: {
      fontSize: 10,
      cellPadding: 2
    },
    margin: { top: 5 },
    didDrawPage: (data) => {
      // Add footer
      const pageCount = doc.internal.getNumberOfPages();
      doc.setFontSize(10);
      doc.setTextColor(40);
      doc.text(`Page ${data.pageNumber} of ${pageCount}`, data.settings.margin.left, doc.internal.pageSize.height - 10);
    }
  });

  // Save the PDF
  doc.save(`${heading.replace(/ /g, '_')}.pdf`);
};



// ---------------------------------------------------------- Framer Motion animation functions --------------------------------------------------

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="#00A076"
    strokeLinecap="round"
    {...props}
  />
);

export const MenuToggle = ({ toggle }) => (
  <IconButton onClick={toggle} aria-label="Header Menu Toggle">
    <svg
      width="18"
      height="18"
      style={{ margin: "4px 4px 0px 4px" }}
      viewBox="0 0 23 23"
    >
      <Path
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" },
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" },
        }}
      />
    </svg>
  </IconButton>

);




