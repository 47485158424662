import { Box, IconButton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import './Print ReceiptStyles.css';

const PrintReceiptButton = ({ receiptUrl }) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const downloadReceipt = (e) => {
    if (receiptUrl) {
      e.stopPropagation();
      window.open(receiptUrl, '_blank');
    }
  };

  return (
    <Box className="PrintReceiptContainer">
      <IconButton onClick={() => setOpen(pre => !pre)}>
        <img loading='lazy' src={`${process.env.REACT_APP_CDN_ICON_RESOURCE_URL}three-dots.svg`} alt="three-dots" />
      </IconButton>
      {open && (
        <Box className="PrintReceiptDropdownBody" ref={dropdownRef} >
          <Box className="PrintReceiptDropdownItem" onClick={downloadReceipt}>
            <p className={!receiptUrl ? 'NoreceiptText' : ''}>{receiptUrl ? 'Print Receipt' : 'No Receipt'}</p>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PrintReceiptButton;
