import React, { useEffect, useState } from 'react';
import { useApiService } from '../../Redux/Services/Api';

const HealthCheckStatus = () => {
    const [status, setStatus] = useState(null);
    const { fetchData } = useApiService();

    useEffect(() => {
        const fetchHealthData = async () => {

            try {
                const response = await fetchData('api/v1/health');
                if (response?.success) {
                    setStatus(response.status, response.statusCode)
                }
            } catch (e) {
                console.log('error while refreshing:', e);
            }
        };

        fetchHealthData();
    }, []);

    return (
        <div>
            Status: {status}
        </div>
    );
};

export default HealthCheckStatus;
