import React from 'react';
import InvestmentCalculator from '../../../Components/Shared/UI/InvestmentCalculator/InvestmentCalculator';
import WisexAdvantages from '../../../Components/Layout/PreLogin/FractionalOwnership/WisexAdvantages/WisexAdvantages';
import StartWithWisex from '../../../Components/Shared/UI/StartWithWisex/StartWithWisex';

const InvestmaneCalculator = () => {
    return (
        <div>
            <InvestmentCalculator styles={{ margin: '1rem 0 3rem 0 ' }} />
            <WisexAdvantages />
            <StartWithWisex
                className="InvestmentCalculatorStartWithWisex"
                heading="Discover the Power of Smart Investments and Begin Building Your Wealth with a"
                headingSpan="Real Estate Portfolio Today!"
            />
        </div>
    );
}

export default InvestmaneCalculator;
