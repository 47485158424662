import React, { memo, useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';

const RevealAnimation = ({ children, className, styles, mainStyles, containerwidth }) => {
  const ref = useRef(null);
  const isInViwe = useInView(ref);
  const animationControl = useAnimation();

  useEffect(() => {
    if (isInViwe) {
      animationControl.start('visible');
    }
  }, [isInViwe]);

  return (
    <motion.div
      style={{ mainStyles }}
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 55, overflow: 'hidden' },
        visible: { opacity: 1, y: 0, overflow: 'hidden', width: containerwidth ? containerwidth : 'auto' }
      }}
      initial='hidden'
      animate={animationControl}
      transition={{ duration: 0.3, delay: 0.25 }}

    >
      <div className={className} style={styles}>{children}</div>
    </motion.div>
  );
};

export default memo(RevealAnimation);
