import {createSlice} from '@reduxjs/toolkit';

const userReducer = createSlice({
  name: 'userData',
  initialState: JSON.parse(localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')) : {},
  reducers: {
    setUserData: (state, action) => {
      console.log('payload',action.payload);
      if(Object.keys(action.payload).length === 0){
        localStorage.clear();
        return {};
      }
      else{
        localStorage.setItem('userData', JSON.stringify({...state, ...action.payload}));
        return {
          ...state,
          ...action.payload
        };
      }

    }
    // setActiveUserData:(state, action) => {
    //     if(Object.keys(action.payload).length === 0){
    //         localStorage.clear();
    //         return {};
    //     }
    //     else{
    //         localStorage.setItem('activeUserData', JSON.stringify({...state, ...action.payload}));
    //         return {
    //             ...state,
    //             ...action.payload
    //         }
    //     }
    // }
  }
});

export const {setUserData} = userReducer.actions;

export default userReducer.reducer;